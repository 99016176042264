@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif !important;
}

a {
  color: #033076 !important;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.background_Image {
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat !important;
  background-position: center;
  background-size: cover !important;
  background: linear-gradient(to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0)), url("./assets/images/bg.svg");
}

/*--------------------- 
        Scrollbar css 
----------------------------------*/
*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  position: absolute;
  /* display: none; */
  scrollbar-gutter: stable both-edges;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #e4e4e4;
  border-radius: 10px;
  border: 2px solid transparent;
}

/*--------------------- 
        Scrollbar css 
----------------------------------*/

.tooltip_custom {
  z-index: 9999999 !important;
}

/* .scrollable-content {
  margin-left: auto;
  margin-right: auto;
}
.scrollable-content:hover,
.scrollable-content:active,
.scrollable-content:focus {
  overflow-y: scroll;
} */



/*--------------------- 
        MUI css 
----------------------------------*/
.sidebar_text span {
  font-size: 16px !important;
  line-height: 1 !important;
}

.active_sidebar {
  background: #fef9f8 !important;
}

.user_icon {
  width: 30px;
  height: 30px;
}

.menu_icon {
  font-size: 24px;
}

.tab_button {
  border-radius: 30px !important;
}

.table_select fieldset {
  border: unset !important;
}

.table_select .MuiSelect-select {
  line-height: 2 !important;
  box-shadow: unset !important;
  padding: unset !important;
  padding-right: 25px !important;
  font-size: 16px !important;
  font-weight: 700 !important;
}

.table_select .MuiSelect-icon {
  top: calc(50% - 0.6em) !important;
}

.table_top_select fieldset {
  border: unset !important;
}

.base-Popper-root {
  z-index: 9999 !important;
}

.table_icon {
  width: 32px;
  height: 32px;
  padding: 6px;
}

.image_upload_icon {
  width: 94px;
  height: 94px;
}

.verify_card_img {
  object-fit: contain;
  mix-blend-mode: darken;
}

@media (max-width:600px) {
  .table_icon {
    width: 25px !important;
    height: 25px !important;
    padding: 5px !important;
  }

  .sidebar_text span {
    font-size: 16px !important;
  }

  .download_button .MuiButton-startIcon {
    margin: auto !important;
  }

  .user_icon {
    width: 26px;
    height: 26px;
  }

  .menu_icon {
    font-size: 21px;
  }

  .image_upload_icon {
    width: 74px;
    height: 74px;
  }
}

/*--------------------- 
        MUI css 
----------------------------------*/

/*--------------------- 
        DateRange css 
----------------------------------*/
.date_range {
  width: max-content !important;
}

.date_range button {
  background: #fff;
  color: #00BFF5;
  font-weight: 400;
  border: unset !important;
  border-radius: 10px;
  padding: 9px 10px;
  text-align: start;
  font-size: 15px;
  width: 100%;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
}

.date_range_width button {
  width: unset !important;
  background: #00BFF5 !important;
  color: #fff !important;
}

.daterangepicker .ranges li.active {
  background-color: #00BFF5 !important;
}

.daterangepicker.opensright:before,
.daterangepicker.opensright:after {
  right: 20px !important;
  left: unset !important;
  display: none !important;
}

.show-calendar {
  right: 20px !important;
  overflow: scroll;
  height: 303px;
}

.applyBtn {
  background: #00BFF5 !important;
  border: 1px solid #00BFF5 !important;
}

.daterangepicker td.in-range {
  background-color: #00BFF520 !important;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #00BFF5 !important;
}

.date_range_icon {
  padding: 4px;
}

.date_range_icon svg {
  font-size: 22px;
}

@media (max-width:600px) {
  .daterangepicker {
    right: 20px !important;
  }

  .date_range button {
    padding: 13px 10px;
    font-size: 14px;
  }

  .date_range_icon {
    padding: 3px;
    display: none !important;
  }

  .date_range_icon svg {
    font-size: 18px;
  }
}





/*--------------------- 
        DateRange css 
----------------------------------*/


/*--------------------- 
        Radix UI css 
----------------------------------*/
.notificationDropDownTrigger {
  background: transparent;
  display: flex;
  border: unset !important;
  outline: unset !important;
}

[data-radix-popper-content-wrapper] {
  top: 9px !important;
  z-index: 9999 !important;
}

.notificationDropDownContent {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.12);
  z-index: 1000;
  padding: 7px;
}

.notificationDropDownMenu {
  border: unset !important;
  outline: unset !important;
  font-size: 14px !important;
  cursor: pointer;
}

/* .notificationDropDownMenu:hover {
  background: #00AD6F15;
} */

/*--------------------- 
        Radix UI css 
----------------------------------*/